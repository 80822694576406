import React from 'react'
import Layout from '../../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container column is-8">
        <div className="content">
          <h1>Thank you for your generous donation!</h1>
          <h2>Gift Aid</h2>
          <p>
            If you are elligible, please consider Gift Aiding the donation you have just made, as this would 
            allow us to claim an additional 25% on top of your donation. All you need to do is fill out an online Gift Aid 
            Declaration form which can be found <a href="/get-involved/donate/gift-aid">here</a>.
          </p>
          <br></br>
          <figure className="image thank-you-image">
            <img src="/img/children_holding_up_origami.jpg" alt="children holding up origami"/>
          </figure>
        </div>
      </div>
    </section>
  </Layout>
)
